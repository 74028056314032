<script>

	let msgStatus = "";
	let inputText = "";
	let imageUrl = "";
	let isLoading = false;

	async function sendData() {
		const formData = new FormData();
		formData.append("prompt", inputText);
		const response = await fetch(
			"https://aifity.machs-mit-marketing.de/api/v1/generate",
			{
				method: "POST",
				body: formData
			}
		);

		const { id } = await response.json();

		let timeout;
		const startTime = Date.now();
		msgStatus = "InProcess";
		isLoading = true;
		async function checkStatus() {
			const checkResponse = await fetch(
				`https://aifity.machs-mit-marketing.de/api/v1/id/${id}/get/data`
			);
			const { status, output } = await checkResponse.json();
			
			if (status === "succeeded") {
				clearTimeout(timeout);
				msgStatus = "Success";
				imageUrl = output[0]; // Assuming there is only one image URL in the output
				isLoading = false;
			} else if (Date.now() - startTime >= 30000) {
				msgStatus = "Timeout";
				isLoading = false;
			} else {
				timeout = setTimeout(checkStatus, 1000);
			}
		}
		checkStatus();
	}
</script>

<main>
	<h1>AIFity Prediction App</h1>

	{#if msgStatus === "InProcess"}
		{#if isLoading}
		<svg class="loader" viewBox="0 0 50 50">
			<circle
				class="path"
				cx="25"
				cy="25"
				r="20"
				fill="none"
				stroke-width="4"
			/>
		</svg>
		{/if}
	{/if}
	{#if msgStatus === "Success"}
		<div class="result">		
			{#if imageUrl}
				<!-- svelte-ignore a11y-img-redundant-alt -->
				<img src={imageUrl} alt="Generated Image" />
			{/if}
		</div>
	{/if}

	{#if msgStatus === "Timeout"}
		<p>Kein Ergebnis innerhalb von 30 Sekunden.</p>
	{/if}

	<div class="input-container">
		<input type="text" bind:value={inputText} placeholder="Key-Wörter" />
		<button on:click={sendData}>Bild generieren</button>
	</div>
</main>

<style>
	main {
		max-width: 600px;
		margin: 0 auto;
		padding: 2rem;
		text-align: center;
	}

	h1 {
		font-size: 2rem;
		margin-bottom: 1rem;
	}

	.input-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 2rem;
	}

	input {
		padding: 0.5rem;
		font-size: 1rem;
		border: 1px solid #ccc;
		border-radius: 4px;
		margin-right: 0.5rem;
	}

	button {
		background-color: #007bff;
		color: #fff;
		padding: 0.5rem 1rem;
		border: none;
		border-radius: 4px;
		cursor: pointer;
	}

	.result {
		margin-top: 2rem;
	}

	img {
		max-width: 100%;
		border: 2px solid #007bff;
		border-radius: 4px;
		margin-top: 1rem;
	}

	.loader {
		animation: rotate 1s linear infinite;
		width: 50px;
		height: 50px;
	}

	.path {
		stroke: #007bff;
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
</style>
